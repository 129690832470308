import React, {useContext, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import {API_URL} from "../constants";
import {AuthContext} from "./AuthContext";

const Header = () => {
    const {token, setToken, admin, setAdmin, hasRole, hasPermission} = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onLogout = async () => {
        localStorage.removeItem("authToken");

        setIsLoggingOut(true); // Set logging out to true
        try {
            // Make an API call to logout from the backend
            await axios.post(
                `${API_URL}/api/admin/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Clear the token from local storage (or wherever it's stored)
            localStorage.removeItem("authToken");
            // Update the token in the AuthContext
            setToken(null);
            setAdmin(null);

            // Redirect the user to the login or home page
            navigate("/");
        } catch (error) {
            console.error("Failed to logout:", error);
            localStorage.removeItem("authToken");
            setToken(null);
            setAdmin(null);
            navigate("/");
        } finally {
            setIsLoggingOut(false);
        }
    };

    const getInitials = (name) => {
        return name
            ? name
                .split(" ")
                .map((part) => part[0])
                .join("")
                .toUpperCase()
            : "";
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light px-2">
            <Link className="navbar-brand" to="/">
                <img
                    src="https://file-uploads-navi.s3.ca-central-1.amazonaws.com/logos/NaviPickups.png"
                    height="50"
                    alt=""
                />
            </Link>
            <button className="navbar-toggler" type="button" onClick={toggle}>
                <span className="navbar-toggler-icon"/>
            </button>
            {token ? (
                <>
                    <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/dashboard" ? "active" : ""}`} to="/dashboard">
                                    Dashboard
                                </Link>
                            </li>
                            {hasPermission("driver-view") && (
                                <li className="nav-item dropdown">
                                    <a
                                        className={`nav-link dropdown-toggle ${location.pathname.startsWith("/drivers") ? "active" : ""}`}
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Drivers
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <Link className="dropdown-item" to="/drivers">
                                                Driver List
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/drivers/performance">
                                                Performance
                                            </Link>
                                        </li>
                                        {hasPermission("driver-update") && (
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/drivers/notify-all">
                                                        Notify All
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/drivers/payout-reports">
                                                        Payout Reports
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {hasPermission("vehicle-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/vehicles") ? "active" : ""}`} to="/vehicles">
                                        Vehicles
                                    </Link>
                                </li>
                            )}
                            {hasPermission("pickup-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/pickups") ? "active" : ""}`} to="/pickups">
                                        Pickups
                                    </Link>
                                </li>
                            )}
                            {hasPermission("user-view") && (
                                <li className="nav-item dropdown">
                                    <a
                                        className={`nav-link dropdown-toggle ${location.pathname.startsWith("/users") ? "active" : ""}`}
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Customers
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <Link className="dropdown-item" to="/users">
                                                Customer List
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/users/performance">
                                                Performance
                                            </Link>
                                        </li>
                                        {hasPermission("user-update") && (
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/users/create">
                                                        Create Customer
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/users/notify-all">
                                                        Notify All
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {hasPermission("promo-code-view") && (
                                <li className="nav-item dropdown">
                                    <a
                                        className={`nav-link dropdown-toggle ${location.pathname.startsWith("/promo-codes") ? "active" : ""}`}
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Promo
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <Link className="dropdown-item" to="/promo-codes">
                                                Promo Code List
                                            </Link>
                                        </li>
                                        {hasPermission("promo-code-update") && (
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/promo-codes/create">
                                                        Create Promo Code
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {hasPermission("cargo-insurance-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/cargo-insurances") ? "active" : ""}`} to="/cargo-insurances">
                                        Cargo Insurances
                                    </Link>
                                </li>
                            )}
                            {hasPermission("truck-type-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/truck-types/prices") ? "active" : ""}`} to="/truck-types/prices">
                                        Truck Types
                                    </Link>
                                </li>
                            )}
                            {hasPermission("tax-rate-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/tax-rates") ? "active" : ""}`} to="/tax-rates">
                                        Tax Rates
                                    </Link>
                                </li>
                            )}
                            {hasPermission("contact-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/contacts") ? "active" : ""}`} to="/contacts">
                                        Contacts
                                    </Link>
                                </li>
                            )}
                            {hasPermission("device-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/devices") ? "active" : ""}`} to="/devices">
                                        Devices
                                    </Link>
                                </li>
                            )}
                            {hasPermission("setting-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/settings") ? "active" : ""}`} to="/settings">
                                        Settings
                                    </Link>
                                </li>
                            )}
                            {hasPermission("admin-log-view") && (
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname.startsWith("/admin-logs") ? "active" : ""}`} to="/admin-logs">
                                        Admin Logs
                                    </Link>
                                </li>
                            )}
                            {hasRole("super-admin") && (
                                <li className="nav-item dropdown">
                                    <a className={`nav-link dropdown-toggle ${location.pathname.startsWith("/coworkers") ? "active" : ""}`}
                                        href="#"
                                        id="coworkerDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Coworkers
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to="/coworkers">Coworkers</Link></li>
                                        <li><Link className="dropdown-item" to="/coworkers/create">Create Coworker</Link></li>
                                        <li><Link className="dropdown-item" to="/coworkers/roles">Roles</Link></li>
                                        <li><Link className="dropdown-item" to="/coworkers/roles/create">Create Role</Link></li>
                                        <li><Link className="dropdown-item" to="/coworkers/permissions">Permissions</Link></li>
                                        <li><Link className="dropdown-item" to="/coworkers/permissions/create">Create Permission</Link></li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </div>
                    {/* Admin profile and logout dropdown */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle d-flex align-items-center"
                                href="#"
                                id="adminDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {admin?.avatar ? (
                                    <span style={{
                                        display: "inline-block",
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        backgroundImage: `url(${admin.avatar})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }} />
                                ) : (
                                    <span className="default-avatar">{getInitials(admin?.name)}</span>
                                )}
                                &nbsp; {admin?.name}
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="adminDropdown">
                                <li>
                                    <Link className="dropdown-item" to="/my-profile">
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    <button className="dropdown-item" onClick={onLogout}>
                                        {isLoggingOut ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp;Logging out...
                                            </>
                                        ) : (
                                            "Logout"
                                        )}
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            ) : null}
        </nav>
    );
};

export default Header;
